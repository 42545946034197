<template>
  <div>
    <Package-add-new
      :is-add-new-package-sidebar-active.sync="isAddNewPackageSidebarActive"
      @clicked="one"
    />
    <Package-edit
      :is-edit-package-sidebar-active.sync="isEditPackageSidebarActive"
      :selected="selected"
      @clicked="two"
    />
    <b-card>
    <b-row>
      <b-col
        cols="12"
        md="8"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <b-form-group>
          <v-select
            v-model="perPage"
            label="Number of rows"
            :options="pageOptions"
            :clearable="false"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="4"
      >
        <div class="d-flex align-items-center justify-content-end">

          <b-button
            variant="primary"
            @click="isAddNewPackageSidebarActive = true"
            v-if="$can('read', 'staff')"
          >
            <span class="text-nowrap">Add Package</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      striped
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item :to="{ name: 'apps-Member-view', params: { id: data.item.id } }">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">Details</span>
          </b-dropdown-item>

          <b-dropdown-item :to="{ name: 'apps-Member-edit', params: { id: data.item.id } }">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>

          <b-dropdown-item>
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #cell(package_type)="data">
        <b-badge :variant="type[1][data.value]">
          {{ type[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(status_number)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(expire)="data">
        {{data.item.expiry_figure}} {{data.item.expiry_unit}}
      </template>
    </b-table>
    <b-row>
      <b-col class="mt-1">
        Total : {{ totalRows }} , Display Row {{ startRow }} to {{ endRow }}
      </b-col>
      <b-col>
        <!--<b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          align="right"
          size="md"
          class="my-0"
          @change="handleChangePage"
        />-->
      </b-col>
    </b-row>
  </b-card>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import vSelect from 'vue-select'
import PackageAddNew from './PackageListAddNew.vue'
import PackageEdit from './PackageListEdit.vue'
import Ripple from 'vue-ripple-directive'
import {
  BTable, BAvatar, BCard, BCardBody, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BInputGroupPrepend,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    PackageAddNew,
    PackageEdit,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BPagination,
    BCardCode,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      test_string : null,
      isAddNewPackageSidebarActive:false,
      isEditPackageSidebarActive:false,
      status: [{
        1: 'ENABLED', 2: 'DISABLED', 3: 'DELETED'
      },
      {
        1: 'light-success', 2: 'danger', 3: 'info'
      }],
      centerOptions: [],
      isAddNewMemberSidebarActive: false,
      searchString: null,
      perPage: 10,
      startRow: 1,
      endRow: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      fields: [
        { key: 'name', label: 'Name' , sortable: true},
       { key: 'package_type', label: 'Type' },
        { key: 'price', label: 'price' },
        { key: 'quantity', label: 'QTY' },
         { key: 'expire', label: 'Expire' },
           { key: 'status_number', label: 'STATUS' , sortable: true}
         ],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [{
        1: 'Enabled', 2: 'Disabled', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-success', 2: 'light-danger', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      type: [{
        'CLASS': 'CLASS', 'PACKAGE': 'PACKAGE'
      },
      {
        'CLASS': 'light-warning', 'PACKAGE': 'light-info'
      }],
      selectMode: 'single',
      selected: [],
    }
  },
  mounted() {
  },
  watch: {
    currentPage: {
      handler(newValue, oldValue) {
        this.get_data()
      },
    },
    searchString: {
      handler(newValue, oldValue) {
        // console.log("search")
        this.searchString = newValue.toUpperCase()
        this.handleSearch()
      },
    },
    perPage: {
      handler(newValue, oldValue) {
        if (newValue < 9) {
          this.perPage = 10
        }
        // console.log("search")
        this.handleSearch()
      },
    },
  },
  created() {
    this.get_data()
    this.get_center_data()
  },
  methods: {
    test1() {
      this.$axiosGet2(process.env.VUE_APP_API_BASE+`/members/?perpage=${this.perPage}&page=${this.currentPage}&search=${this.searchString}`)
      .then(res => {
        console.log(res)
      /*    this.items = res.members
          this.totalRows = res.total
          this.startRow = res.startRow
          this.endRow = res.endRow
          //  console.log( res.data.members.length)
        */})
    },
    one() {
      this.isAddNewPackageSidebarActive = false

      this.get_data()
    },
    two() {
      this.isEditPackageSidebarActive = false
      this.get_data()
    },
    get_data() {
      this.$http.get(process.env.VUE_APP_API_BASE+`/packages/?type=all&perpage=${this.perPage}&page=${this.currentPage}&search=${this.searchString}`)
        .then(res => {
          this.items = res.data.packages
          this.totalRows = res.data.total
          this.startRow = res.data.startRow
          this.endRow = res.data.endRow
          //  console.log( res.data.members.length)
        })
    },
    get_center_data() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/centers/')
        .then(res => {
          this.centerOptions = res.data.centers
        })
    },
    handleChangePage() {

    //  this.get_data()
    },
    onRowSelected(items) {
      this.selected = items[0]
      if (items.length > 0 ) {
        this.isEditPackageSidebarActive = true
      }
      //this.$router.push(`/apps/member/edit/${items[0].member_id}/`)
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },

    handleSearch() {
      // let _print = `The user searched for: ${searching}\n`
      // this.$refs.pre.appendChild(document.createTextNode(_print))
    // this.searchString = searching
    // this.currentx = 1
    // this.get_data()
      this.searchTimeOut()
    },
    searchTimeOut() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        // your code
        this.currentPage = 1
        this.get_data()
      }, 500)
    },
  },
}
</script>

<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}

</style>
